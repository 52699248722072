@font-face {
	font-family: "circular";
	src: url("./fonts/circular-book/circular-book.woff") format("woff"),
		url("./fonts/circular-book/circular-book.ttf") format("truetype"),
		url("./fonts/circular-book/circular-book.eot") format("eot"),
		url("./fonts/circular-book/circular-book.svg") format("svg");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: "circular";
	src: url("./fonts/circular-medium/circular-medium.woff") format("woff"),
		url("./fonts/circular-medium/circular-medium.ttf") format("truetype"),
		url("./fonts/circular-medium/circular-medium.eot") format("eot"),
		url("./fonts/circular-medium/circular-medium.svg") format("svg");
	font-weight: 300;
	font-style: bold;
}

@font-face {
	font-family: "circular";
	src: url("./fonts/circular-black/circular-black.woff") format("woff"),
		url("./fonts/circular-black/circular-black.ttf") format("truetype"),
		url("./fonts/circular-black/circular-black.eot") format("eot"),
		url("./fonts/circular-black/circular-black.svg") format("svg");
	font-weight: 700;
	font-style: bold;
}

html,
body,
.App,
footer {
	font-family: "circular";
	font-size: 16px;
	line-height: 22px;
	font-weight: 100;
	color: #1e2029;
}

a {
	text-decoration: underline;
	color: inherit;
	font-weight: 100;
}

nav a {
	text-decoration: none;
}

h2 {
	margin-top: 0;
	font-size: 24px;
	line-height: 28px;
}

img {
	width: 100%;
}

footer {
	overflow: hidden;
	line-height: 24px;
}

.cookieConsent {
	font-family: "circular";
	font-weight: 300;
	line-height: 24px;
	text-shadow: none;
	align-items: none;
	z-index: 10000000000 !important;
}

.cookieConsent a {
	text-decoration: underline;
	color: #ffffff !important;
	font-weight: 300;
	text-shadow: none;
}

.cookieConsent button {
	font-family: "circular" !important;
	background: none !important;
	border: 0;
	font-size: 16px;
	line-height: 28px;
	border: 1px solid #ffffff !important;
	border-radius: 4px !important;
	color: #ffffff !important;
	font-weight: 700;
}

button.cookie-btn {
	text-decoration: none;
	border: 0 !important;
	background: #6b2fad !important;
	border: 1px solid #6b2fad !important;
	color: #ffffff !important;
}

footer {
	font-family: "circular" !important;
}

.container {
	width: 58rem;
	margin: auto;
	padding: 100px 0;
}

@media (max-width: 1024px) {
	.container {
		padding: 50px 0;
		width: 48rem;
	}
}

@media (max-width: 768px) {
	.container {
		width: auto;
		padding: 30px 16px;
		display: flex;
		justify-content: center;
		overflow: hidden;
	}

	.accordion-header::after {
		content: "\f107";
	}

	.accordion-header--active::after {
		content: "\f106";
	}

	.accordion-body {
		transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
	}

	.accordion-body--active {
		transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
	}
}
@media (min-width: 768px) {
	.accordion-body,
	.accordion-body--active {
		min-height: 360px !important;
		height: 360px !important;
		visibility: visible !important;
	}

	html,
	body,
	.App {
		font-size: 20px;
		line-height: 30px;
	}

	h2 {
		font-size: 48px;
		line-height: 1.1;
	}
}
